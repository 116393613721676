import axios from 'axios'
import emailjs from 'emailjs-com'
import React, { useState } from 'react'

const Result = () => {
	return (
		<p className='success-message'>
			Ваше сообщение было отправлено, мы свяжемся с Вами в ближайшее время!.
		</p>
	)
}

function ContactForm({ props, formStyle }) {
	const [result, showresult] = useState(false)

	const sendEmail = e => {
		e.preventDefault()
		emailjs
			.sendForm(
				'service_3n6yrtc',
				'template_aj3zuuq',
				e.target,
				'FLUg8xS2H_yp_LcfV'
			)
			.then(
				result => {
					console.log(result.text)
				},
				error => {
					console.log(error.text)
				}
			)
		const CRM_DEAL = `https://toovehikz.bitrix24.kz/rest/651/scx61c8j7ti1eplz/crm.deal.add.json?FIELDS[TITLE]=Новый Лид Dataline24.kz&FIELDS[CATEGORY_ID]=43&FIELDS[STAGE_ID]=C43:NEW&FIELDS[ASSIGNED_BY_ID]=809&FIELDS[COMMENTS]=Телефон: ${e.target.phone.value} Имя: ${e.target.fullname.value} Сообщение: ${e.target.message.value}`
		axios.get(CRM_DEAL).then(result => console.log(result))
		e.target.reset()
		showresult(true)
	}

	setTimeout(() => {
		showresult(false)
	}, 5000)

	return (
		<form className={`${formStyle}`} action='' onSubmit={sendEmail}>
			<div className='form-group'>
				<input type='text' name='fullname' placeholder='Имя' />
			</div>
			<div className='form-group'>
				<input type='text' name='phone' placeholder='Номер Телефона' required />
			</div>
			<div className='form-group'>
				<textarea name='message' placeholder='Ваше сообщение'></textarea>
			</div>

			<div className='form-group'>
				<button
					type='submit'
					className='btn-default-form btn-medium btn-border round btn-icon'
				>
					Отправить
				</button>
			</div>

			<div className='form-group'>{result ? <Result /> : null}</div>
		</form>
	)
}
export default ContactForm
